@import "../../css-config/mixins.scss";

.contact_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1.75rem;
    padding: 8rem 2rem 4rem 2rem;
    @include xl {
        padding: 2rem 2rem;
        gap: 1.35rem;
    }

    @include xl {
        padding: 7rem 10.688rem;
        gap: 1.35rem;
    }

    @include xll {
        padding: 7rem 10.688rem;
        gap: 1.35rem;
    }

    @include xxl {
        padding: 7rem 13.688rem;
        gap: 1.35rem;
    }
    h1 {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 36px;
        line-height: 65px;
        text-align: center;
        color: #FFFFFF;
        @include xsm {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            line-height: 35px;
            text-align: center;
            flex-wrap: wrap;
        }

        @include xl {
            width: auto;
            font-family: 'Poppins';
            font-weight: 600;
            font-size: 36px;
            line-height: 65px;
            text-align: center;
        }

        @include xll {
            font-family: 'Poppins';
            font-weight: 600;
            font-size: 36px;
            line-height: 65px;
            text-align: center;
        }

        @include xxl {
            font-family: 'Poppins';
            font-weight: 600;
            font-size: 36px;
            line-height: 65px;
            text-align: center;
        }
    }

    .social-icons {
        display: flex;
        align-items: center;
        gap: 1.25rem;
        .icon {
            font-size: 32px;
            line-height: 30px;
            text-align: center;
            color: #FFFFFF;
            transition: all .4s linear;
            @include xl {
                font-size: 50px;
                line-height: 50px;
                text-align: center;
                color: #FFFFFF;
                transition: all .4s linear;
            }

            @include xll {
                font-size: 50px;
                line-height: 50px;
                text-align: center;
                color: #FFFFFF;
                transition: all .4s linear;
            }
        }
        .icon:hover {
            color: #F31768;
            transform: scale(1.07);
        }
    }

    .email {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        gap: 1.25rem;
        margin-top: 5rem;
        @include xl {
            margin-top: 7rem;
        }
        @include xll {
            margin-top: 7rem;
        }
        .icon {
            font-size: 32px;
            line-height: 30px;
            text-align: center;
            color: #FFFFFF;
            transition: all .4s linear;
            @include xl {
                font-size: 50px;
                line-height: 50px;
                text-align: center;
                color: #FFFFFF;
                transition: all .4s linear;
            }
        }
        .icon:hover {
            color: #F31768;
            transform: scale(1.07);
        }

        p{
            font-family: 'Poppins';
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
            color: #FFFFFF;
            @include xl {
                font-family: 'Poppins';
                font-weight: 700;
                font-size: 36px;
                line-height: 65px;
                color: #FFFFFF;
            }
        }
    }

    .form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding-top: 4rem;
        @include xl {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            padding-top: 4rem;
        }
        .input_field {
            width: 100%;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: baseline;
            gap: 3rem;
            @include xl {
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: space-between;
                gap: 3rem;
            }

            @include xll {
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 3rem;
            }
            input {
                width: 100%;
                outline: none;
                border: none;
                background: none;
                padding: 1rem 0;
                border-bottom: 2px solid #292B3C;
                border-radius: 3px;
                font-family: 'Montserrat', sans-serif;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                color: #707281;
                @include xl {
                    width: 50%;
                    outline: none;
                    border: none;
                    background: none;
                    padding: 1rem 0;
                    border-bottom: 2px solid #292B3C;
                    border-radius: 3px;
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    color: #707281;
                }

                @include xll {
                    width: 50%;
                    outline: none;
                    border: none;
                    background: none;
                    padding: 1rem 0;
                    border-bottom: 2px solid #292B3C;
                    border-radius: 3px;
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    color: #707281;
                }
            }
            .icon1 {
                position: absolute;
                font-size: 16px;
                line-height: 16px;
                color: #707281;
                right: 2.3%;
                top: 13%;
                @include xl {
                    position: absolute;
                    font-size: 16px;
                    line-height: 16px;
                    color: #707281;
                    right: 53.3%;
                    top: 17%;
                }

                @include xll {
                    position: absolute;
                    font-size: 16px;
                    line-height: 16px;
                    color: #707281;
                    right: 52.8%;
                    top: 18%;
                }

                @include xxl {
                    position: absolute;
                    font-size: 16px;
                    line-height: 16px;
                    color: #707281;
                    right: 52.3%;
                    top: 18.2%;
                }
            }

            .icon2 {
                position: absolute;
                font-size: 16px;
                line-height: 16px;
                color: #707281;
                right: 2.3%;
                top: 29%;
                @include xl {
                    position: absolute;
                    font-size: 16px;
                    line-height: 16px;
                    color: #707281;
                    right: 1.3%;
                    top: 17%;
                }

                @include xll {
                    position: absolute;
                    font-size: 16px;
                    line-height: 16px;
                    color: #707281;
                    right: 1.3%;
                    top: 19%;
                }

                @include xxl {
                    position: absolute;
                    font-size: 16px;
                    line-height: 16px;
                    color: #707281;
                    right: 0.7%;
                    top: 19%;
                }
            }
        }

        .text textarea{
            width: 100%;
            outline: none;
            border: none;
            resize: none;
            border-radius: 1px;
            background: none;
            padding: 1rem 0;
            border-bottom: 2px solid #292B3C;
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #707281;
            @include xl {
                width: 100%;
                outline: none;
                border: none;
                resize: none;
                border-radius: 1px;
                background: none;
                padding: 1rem 0;
                border-bottom: 2px solid #292B3C;
                font-family: 'Montserrat', sans-serif;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
            }
            @include xll {
                width: 100%;
                outline: none;
                border: none;
                resize: none;
                border-radius: 1px;
                background: none;
                padding: 1rem 0;
                border-bottom: 2px solid #292B3C;
                font-family: 'Montserrat', sans-serif;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
            }
        }

        .checkbox {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            align-items: baseline;
            margin-top: 1rem;
            #checkbox {
                outline: none;
                border: none;
                background: #FFFFFF;
                border-radius: 2.5px;
                width: 13px;
                height: 13px;
            }
            p {
                font-family: 'Montserrat', sans-serif;
                font-weight: 400;
                font-size: 14px;
                line-height: 25px;
                color: #707281;
                @include xl {
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 30px;
                    color: #707281;
                }
            }
        }

        #submit {
            width: 107.69px;
            height: 47px;
            background: #F31768;
            border: 1px solid #292B3C;
            border-radius: 5px;
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            text-transform: uppercase;
            color: #FFFFFF;
            cursor: pointer;
            transition: all 0.4s linear;
        }
        #submit:hover {
            background: #f54a89;
            transform: translateX(10px);
        }
    }
}