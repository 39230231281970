@import "../../css-config/mixins.scss";

.footer_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 2rem;
    @include xl {
        padding: 5rem 10.688rem;
    }
    @include xll {
        padding: 5rem 10.688rem;
    }
    @include xxl {
        padding: 5rem 13.688rem;
    }
    .heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid #ffffff;
        padding: 1rem 0rem;
        h1 {
            font-family: 'Poppins', sans-serif;
            font-weight: 700;
            font-size: 30px;
            line-height: 40px;
            text-transform: capitalize;
            color: #FFFFFF;
            @include xl {
                font-family: 'Poppins', sans-serif;
                font-weight: 700;
                font-size: 64px;
                line-height: 68px;
                text-transform: capitalize;
                color: #FFFFFF;
            }

            @include xll {
                font-family: 'Poppins', sans-serif;
                font-weight: 700;
                font-size: 64px;
                line-height: 68px;
                text-transform: capitalize;
                color: #FFFFFF;
            }
        }
        .arrow {
            font-size: 1.35rem;
            cursor: pointer;
            transform: translateY(0.325rem);
            @include xl {
                font-size: 1.55rem;
                cursor: pointer;
                transform: translateX(-1rem);
            }
            @include xll {
                font-size: 1.55rem;
                cursor: pointer;
                transform: translateX(-1rem);
            }
        }
    }

    p {
        width: 100%;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        text-transform: capitalize;
        color: #FFFFFF;
        @include xl {
            width: 60%;
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            letter-spacing: -0.02em;
            text-transform: capitalize;
            color: #FFFFFF;
        }

        @include xll {
            width: 60%;
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            letter-spacing: -0.02em;
            text-transform: capitalize;
            color: #FFFFFF;
        }

        @include xxl {
            width: 50%;
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            letter-spacing: -0.02em;
            text-transform: capitalize;
            color: #FFFFFF;
        }
    }

    .footer_nav {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 2.5rem;
        margin-top: 1rem;
        @include xl {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 4rem;
        }
        @include xll {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 4rem;
        }
        .favNav_menu {
            display: flex;
            align-items: center;
            gap: 4rem;
            @include xl {
                display: flex;
                align-items: center;
                gap: 8rem;
            }
            @include xll {
                display: flex;
                align-items: center;
                gap: 8rem;
            }
            li a {
                text-decoration: none;
                font-family: 'Montserrat', sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #FFFFFF;
                cursor: pointer;
            }
            li a:hover {
                color: #F31768;
            }
        }

        .btn {
            width: 150px;
            height: 40px;
            border: 1.5px solid #FFFFFF;
            border-radius: 20px;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 13px;
            color: #FFFFFF;
            cursor: pointer;
            transition: all 0.4s linear;
        }
        .btn:hover {
            background: #F31768;
            border: none;
        }
    }

    .policies {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;
        margin-top: 2rem;
        @include xl {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 3rem;
            margin-top: 2rem;
        }
        .washington p {
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #FFFFFF;
            opacity: 0.4;
        }
        .privacy {
            display: flex;
            align-items: center;
            gap: 2rem;
            li a {
                text-decoration: none;
                font-family: 'Montserrat', sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #FFFFFF;
                opacity: 0.4;
            }
            li a:hover {
                color: #ffffff;
                opacity: 1;
            }
        }
    }
}