@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  background: #000000;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-family: "Poppins", sans-serif;
}

code {
  font-family: "Montserrat", sans-serif;
  font-family: "Poppins", sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

#menu-toggle:checked + #menu {
  display: block;
}

.navicon {
  width: 1.125em;
  height: 0.125em;
}

.navicon::before,
.navicon::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-out;
  content: "";
  background: #ffff;
}

.navicon::before {
  top: 5px;
}

.navicon::after {
  top: -5px;
}

.menu-btn:not(:checked) ~ .menu {
  display: none;
}

.menu-btn:checked ~ .menu {
  display: block;
}

.menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.menu-btn:checked ~ .menu-icon .navicon::before {
  transform: rotate(-45deg);
}

.menu-btn:checked ~ .menu-icon .navicon::after {
  transform: rotate(45deg);
}

.menu-btn:checked ~ .menu-icon .navicon::before,
.menu-btn:checked ~ .menu-icon .navicon::after {
  top: 0;
}

/* * {
  border: 2px solid red;
} */
