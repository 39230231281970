@import "../../css-config/mixins.scss";

.sectionThree_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6rem;
  padding: 1rem 10.688rem;
  @include xsm {
    padding: 1rem 2rem;
    gap: 2rem;
  }
  @include xl {
    gap: 4rem;
    padding: 2rem 10.688rem;
    gap: 5rem;
  }

  @include xll {
    gap: 6rem;
    padding: 2rem 10.688rem;
    gap: 4rem;
  }

  @include xxl {
    gap: 6rem;
    padding: 2rem 13.688rem;
    gap: 5rem;
  }

  h1 {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 48px;
    line-height: 53px;
    letter-spacing: -1.5px;
    color: #ffffff;
    @include xsm {
      font-weight: 600;
      font-size: 30px;
      line-height: 53px;
      letter-spacing: 1px;
    }

    @include xl {
      font-family: "Poppins";
      font-weight: 600;
      font-size: 48px;
      line-height: 53px;
      letter-spacing: -1.5px;
    }

    @include xll {
      font-family: "Poppins";
      font-weight: 600;
      font-size: 48px;
      line-height: 53px;
      letter-spacing: -1.5px;
    }
  }

  .image_gallery {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    .top_img {
      display: flex;
      justify-content: space-between;
      gap: 3rem;
      flex-wrap: wrap;
      .img {
        transition: all 0.4s linear;
      }
      .img:hover {
        transform: scale(1.09);
      }

      .img1 {
        width: 16.222rem;
        height: 16.222rem;
        border-radius: 1px;
        margin-top: 6rem;
        background: url(../../assets/4.jpg) no-repeat center center/cover;
        @include xsm {
          width: 100%;
          margin-top: 2rem;
        }

        @include xl {
          width: 14.222rem;
          height: 14.222rem;
          margin-top: 6rem;
        }

        @include xll {
          width: 16.222rem;
          height: 16.222rem;
          margin-top: 6rem;
        }

        @include xxl {
          width: 18.222rem;
          height: 18.222rem;
          margin-top: 6rem;
        }
      }

      .img2 {
        width: 16.379rem;
        height: 16.379rem;
        border-radius: 1px;
        margin-top: 4rem;
        background: url(../../assets/7.jpg) no-repeat center center/cover;
        @include xsm {
          width: 100%;
          margin-top: 0rem;
        }

        @include xl {
          width: 14.379rem;
          height: 14.379rem;
          margin-top: 4rem;
        }

        @include xll {
          width: 16.379rem;
          height: 16.379rem;
          margin-top: 4rem;
        }

        @include xxl {
          width: 18.379rem;
          height: 18.379rem;
          margin-top: 4rem;
        }
      }

      .img3 {
        width: 23.138rem;
        height: 23.138rem;
        border-radius: 1px;
        background: url(../../assets/19.jpg) no-repeat center center/cover;
        @include xsm {
          width: 100%;
        }

        @include xl {
          width: 21.379rem;
          height: 21.379rem;
        }

        @include xxl {
          width: 23.379rem;
          height: 23.379rem;
        }

        @include xxl {
          width: 25.379rem;
          height: 25.379rem;
        }
      }
    }

    .bottom_img {
      display: flex;
      justify-content: space-between;
      gap: 3rem;
      flex-wrap: wrap;
      .img {
        transition: all 0.4s linear;
      }
      .img:hover {
        transform: scale(1.09);
      }

      .img4 {
        width: 16.222rem;
        height: 16.222rem;
        border-radius: 1px;
        margin-top: 1.25rem;
        background: url(../../assets/11.jpg) no-repeat center center/cover;
        @include xsm {
          width: 100%;
          margin-top: 0rem;
        }

        @include xl {
          width: 14.379rem;
          height: 14.379rem;
          margin-top: 1.25rem;
        }

        @include xll {
          width: 16.222rem;
          height: 16.222rem;
          margin-top: 1.25rem;
        }

        @include xxl {
          width: 18.222rem;
          height: 18.222rem;
          margin-top: 1.25rem;
        }
      }

      .img5 {
        width: 12.999rem;
        height: 12.999rem;
        border-radius: 1px;
        background: url(../../assets/15.jpg) no-repeat center center/cover;
        @include xsm {
          width: 100%;
        }

        @include xl {
          width: 10.99rem;
          height: 10.99rem;
        }

        @include xll {
          width: 12.99rem;
          height: 12.99rem;
        }

        @include xxl {
          width: 14.99rem;
          height: 14.99rem;
        }
      }

      .img6 {
        width: 15.798rem;
        height: 15.798rem;
        border-radius: 1px;
        margin-top: 1.25rem;
        background: url(../../assets/3.jpg) no-repeat center center/cover;
        @include xsm {
          width: 100%;
          margin-top: 0rem;
        }

        @include xl {
          width: 13.798rem;
          height: 13.798rem;
          margin-top: 1.25rem;
        }

        @include xll {
          width: 15.798rem;
          height: 15.798rem;
          margin-top: 1.25rem;
        }

        @include xll {
          width: 17.798rem;
          height: 17.798rem;
          margin-top: 1.25rem;
        }
      }

      .img7 {
        width: 9.464rem;
        height: 9.464rem;
        border-radius: 1px;
        margin-top: 1.25rem;
        background: url(../../assets/1.jpg) no-repeat center center/cover;
        @include xsm {
          width: 100%;
          margin-top: 0rem;
        }

        @include xl {
          width: 7.464rem;
          height: 7.464rem;
          margin-top: 1.25rem;
        }

        @include xll {
          width: 9.464rem;
          height: 9.464rem;
          margin-top: 1.25rem;
        }

        @include xxl {
          width: 11.464rem;
          height: 11.464rem;
          margin-top: 1.25rem;
        }
      }
    }
  }
}
