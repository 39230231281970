@import "../../css-config/mixins.scss";

.secFour_Container {
  width: 100%;
  display: flex;
  align-items: center;
  // padding: 4rem 10.688rem;
  @include xsm {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @include xl {
    padding: 4rem 10.688rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  @include xll {
    padding: 4rem 10.688rem;
  }

  @include xxl {
    padding: 8rem 13.688rem;
  }
  .left_img {
    width: 36.813rem;
    height: 39.625rem;
    background: url(../../assets/sec4_left-img.png) no-repeat;
    background-position: center;
    background-size: contain;
    @include xsm {
      width: 100%;
    }

    @include xl {
      width: 36.813rem;
      height: 32.625rem;
    }

    @include xll {
      width: 36.813rem;
      height: 35.625rem;
    }

    @include xxl {
      width: 38.813rem;
      height: 37.625rem;
    }
  }

  .right_img {
    width: 36.813rem;
    height: 35.625rem;
    background: url(../../assets/sec4_right-img.jpeg) no-repeat;
    // background-position: initial;
    background-size: cover;
    scale: 0.91;
    @include xsm {
      width: 100%;
      background: url(../../assets/sec4_right-img.jpeg) no-repeat;
    }

    @include xl {
      background: url(../../assets/sec4_right-img.jpeg) no-repeat;
      background-position: initial;
      background-size: cover;
      width: 36.813rem;
      height: 32.625rem;
    }

    @include xll {
      background: url(../../assets/sec4_right-img.jpeg) no-repeat;
      background-position: initial;
      background-size: cover;
      width: 36.813rem;
      height: 35.625rem;
    }

    @include xxl {
      background: url(../../assets/sec4_right-img.jpeg) no-repeat;
      background-position: initial;
      background-size: cover;
      width: 38.813rem;
      height: 37.625rem;
    }
  }

  .left_img1 {
    width: 36.813rem;
    height: 35.625rem;
    background: url(../../assets/carou1.png) no-repeat;
    background-position: center;
    background-size: cover;
    @include xsm {
      width: 100%;
    }

    @include xl {
      width: 36.813rem;
      height: 32.625rem;
    }

    @include xll {
      width: 36.813rem;
      height: 35.625rem;
    }

    @include xxl {
      width: 38.813rem;
      height: 37.625rem;
    }
  }

  .right_img1 {
    width: 36.813rem;
    height: 35.625rem;
    background: url(../../assets/carou2.png) no-repeat;
    background-position: initial;
    background-size: cover;
    @include xsm {
      width: 100%;
      background: url(../../assets/carou2.png) no-repeat;
    }

    @include xl {
      background: url(../../assets/carou2.png) no-repeat;
      background-position: initial;
      background-size: cover;
      width: 36.813rem;
      height: 32.625rem;
    }

    @include xll {
      background: url(../../assets/carou2.png) no-repeat;
      background-position: initial;
      background-size: cover;
      width: 36.813rem;
      height: 35.625rem;
    }

    @include xxl {
      background: url(../../assets/carou2.png) no-repeat;
      background-position: initial;
      background-size: cover;
      width: 38.813rem;
      height: 37.625rem;
    }
  }
}
